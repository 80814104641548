class CanvasManager {
  constructor(context, canvas, home) {
    this.ctx = context;
    this.canvas = canvas;
    this.home = home;
    this.outputHistory = [];
    this.resultScore = 0;
    this.canvas.width = 370;
    this.canvas.height = 400;
  }

  render() {
    if (!this.ctx) return;

    let ctx = this.ctx;
    ctx.font = "300 25px Sunflower";

    ctx.textAlign = "center";
    ctx.textBaseline = "middle";

    var width = this.canvas.width;
    var height = this.canvas.height;
    var radius = 15; // 라운드의 반지름. 이 값을 조절하면 라운드 크기를 변경할 수 있습니다.
    var dpr = window.devicePixelRatio || 1;

    // 사각형의 배경색을 부드러운 노란색으로 설정하고 라운드된 모서리를 그리기
    ctx.fillStyle = "#FFE08C";
    ctx.beginPath();
    ctx.moveTo(radius, 0);
    ctx.arcTo(width, 0, width, radius, radius);
    ctx.arcTo(width, height, width - radius, height, radius);
    ctx.arcTo(0, height, 0, height - radius, radius);
    ctx.arcTo(0, 0, radius, 0, radius);
    ctx.closePath();
    ctx.fill();

    // 사각형의 테두리를 빨간색으로 설정하고 라운드된 모서리를 그리기
    ctx.strokeStyle = "#e3a5b0";
    ctx.lineWidth = 5;
    ctx.beginPath();
    ctx.moveTo(radius, 0);
    ctx.arcTo(width, 0, width, radius, radius);
    ctx.arcTo(width, height, width - radius, height, radius);
    ctx.arcTo(0, height, 0, height - radius, radius);
    ctx.arcTo(0, 0, radius, 0, radius);
    ctx.closePath();
    ctx.stroke();

    let intervalX = 40;
    let intervalY = 48;
    this.textPositions = [];

    for (var i = 0; i < this.outputHistory.length; i++) {
      this.textPositions[i] = []; // 각 행에 대한 배열 초기화

      for (var j = 0; j < this.outputHistory[i].length; j++) {
        //console.log(this.outputHistory[i][j]);

        if (i == 0) {
          if (j % 2 == 0) {
            ctx.fillStyle = "#1F50B5";
          } else {
            ctx.fillStyle = "#CF3D82";
          }
        } else if (i == this.outputHistory.length - 1) {
          ctx.fillStyle = "#BC2424";

          ctx.font = "300 35px Sunflower";
        } else {
          ctx.fillStyle = "black";
        }

        let halfLength = this.outputHistory[i].length / 2 - 0.5;
        var x = width / 2 + (j - halfLength) * intervalX;
        var y = intervalY + i * intervalY;
        if (i == this.outputHistory.length - 1) {
          ctx.fillText(this.outputHistory[i][j] + "점", x, y + 13);
        } else {
          ctx.fillText(this.outputHistory[i][j], x, y);
        }

        // 위치 정보 저장
        this.textPositions[i][j] = { x: x, y: y };
      }
    }
    ctx.strokeStyle = "black"; // 라인의 색상 설정
    ctx.lineWidth = 2;

    var offset = 16; // 라인을 조절할 거리
    for (var i = 0; i < this.outputHistory.length; i++) {
      for (var j = 0; j < this.outputHistory[i].length; j++) {
        if (i == 0) continue;
        var currentPos = this.textPositions[i][j];

        // 아래쪽 위치로 라인 그리기 (세로)
        if (i + 1 < this.outputHistory.length && this.textPositions[i + 1][j]) {
          var belowPos = this.textPositions[i + 1][j];
          ctx.beginPath();
          ctx.moveTo(currentPos.x, currentPos.y + offset); // 시작점 조절
          ctx.lineTo(belowPos.x, belowPos.y - offset); // 끝점 조절
          ctx.stroke();
        }
        // 아래쪽 위치로 라인 그리기 (세로)
        if (j > 0 && i + 1 < this.outputHistory.length && this.textPositions[i + 1][j - 1]) {
          var belowPos = this.textPositions[i + 1][j - 1];
          ctx.beginPath();
          ctx.moveTo(currentPos.x, currentPos.y + offset); // 시작점 조절
          ctx.lineTo(belowPos.x, belowPos.y - offset); // 끝점 조절
          ctx.stroke();
        }

        // 오른쪽 위치로 라인 그리기 (가로)
        // if (j + 1 < this.outputHistory[i].length && this.textPositions[i][j + 1]) {
        //   var rightPos = this.textPositions[i][j + 1];
        //   ctx.beginPath();
        //   ctx.moveTo(currentPos.x, currentPos.y);
        //   ctx.lineTo(rightPos.x, rightPos.y);
        //   ctx.stroke();
        // }
      }
    }
  }

  updateResultData(historyData) {
    this.outputHistory = historyData.outputHistory;
    this.resultScore = historyData.resultScore;
    this.render();
  }
}

module.exports = CanvasManager;
