<template>
  <div>
    <h1>이름 궁합 테스트</h1>
    <img class="main-img" src="@/assets/main_img.jpg" alt="description" />
    <div v-if="!resultExist">
      <input class="my-font" v-model="name1" placeholder="첫번째 이름" @input="validateInput() && isSubmitOnce" :class="{ 'is-invalid': isInvalid }" />
      <input class="my-font" v-model="name2" placeholder="두번째 이름" @input="validateInput() && isSubmitOnce" :class="{ 'is-invalid': isInvalid }" />
    </div>
    <div v-if="!resultExist"><button class="result-button" @click="submitForm">결과확인</button></div>

    <div v-if="resultExist" class="my-font-result my-margin">
      <span :style="{ color: colorName1 }">{{ name1 }}</span>
      님이
      <span :style="{ color: colorName2 }">{{ name2 }}</span>
      님을 생각하는 마음
    </div>
    <div></div>
    <MyCanvas ref="my-canvas"> </MyCanvas>
    <div v-if="resultExist">
      <button class="result-button" @click="reloadPage">다시하기</button>
      <button class="switchName-button" @click="submitFormWithSwitchName">이름 순서 바꾸기</button>
    </div>
    <div v-if="resultExist">
      <!-- <share-view-2 /> -->
    </div>
  </div>
</template>

<script>
import MyCanvas from "./../components/MyCanvas.vue";
import CanvasManager from "./../scripts/CanvasManager";
import { add, makeHistoryArray } from "./../scripts/NameRelationMaker";

export default {
  components: {
    MyCanvas,
  },
  data() {
    return {
      manager: null,
      name1: "",
      name2: "",
      isInvalid: false,
      isSubmitOnce: false,
      resultMessage: "",
      colorName1: "#1F50B5", // name1의 글자색
      colorName2: "#CF3D82", // name2의 글자색
      resultExist: false,

      //   if (j % 2 == 0) {
      //         ctx.fillStyle = "#1F50B5";
      //       } else {
      //         ctx.fillStyle = "#CF3D82";
      //       }
    };
  },
  mounted() {
    this.manager = new CanvasManager(this.$refs["my-canvas"].getContext(), this.$refs["my-canvas"].getCanvas(), this);
  },
  methods: {
    submitForm() {
      this.isSubmitOnce = true;
      if (this.name1 == "" || this.name2 == "") {
        alert("입력이 잘못되었습니다. 이름은 한글 2~4글자만 가능합니다.");
        return;
      }
      if (this.isInvalid) {
        alert("입력이 잘못되었습니다. 이름은 한글 2~4글자만 가능합니다.");
      } else if (this.name1 && this.name2) {
        let historyData = makeHistoryArray(this.name1, this.name2);
        this.manager.updateResultData(historyData);
        this.resultExist = true;
        this.resultMessage = this.name1 + "님";
      }
    },
    submitFormWithSwitchName() {
      let tmpNmae = this.name1;
      this.name1 = this.name2;
      this.name2 = tmpNmae;
      this.isSubmitOnce = true;
      if (this.isInvalid) {
        alert("입력이 잘못되었습니다. 이름은 한글 2~4글자만 가능합니다.");
      } else if (this.name1 && this.name2) {
        let historyData = makeHistoryArray(this.name1, this.name2);
        this.manager.updateResultData(historyData);
        this.resultExist = true;
        this.resultMessage = this.name1 + "님";
      }
    },
    reloadPage() {
      window.location.reload();
    },
    validateInput() {
      const koreanRegex = /^[가-힣]{2,4}$/;
      if (!koreanRegex.test(this.name1) || !koreanRegex.test(this.name2)) {
        this.isInvalid = true;
      } else {
        this.isInvalid = false;
      }
    },
    shareContent() {
      // Web Share API 지원 여부 확인
      if (navigator.share) {
        navigator
          .share({
            title: "이름 궁합 테스트", // 공유할 제목 (옵션)
            text: "이름으로 궁합을 확인해보세요", // 공유할 텍스트 (옵션)
            url: window.location.href, // 공유할 URL
          })
          .then(() => console.log("공유 성공!"))
          .catch((error) => console.log("공유 실패:", error));
      } else {
        alert("이 브라우저는 웹 공유 기능을 지원하지 않습니다.");
      }
    },
  },
};
</script>

<style scoped>
.is-invalid {
  border-color: red;
}
.main-img {
  max-width: 400px; /* 최대 너비를 300픽셀로 제한 */
  height: auto; /* 높이는 원본 비율을 유지 */
}
.my-font {
  font-family: "Sunflower", sans-serif;
  font-size: 16px;
  font-weight: 300;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 10px 15px;
  margin: 5px;
  width: 150px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

input.is-invalid {
  border-color: #dc3545;
}

.result-button {
  font-family: "Sunflower", sans-serif;
  font-size: 18px;
  font-weight: 300;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #e04c91;
  color: white;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s;
}

.switchName-button {
  font-family: "Sunflower", sans-serif;
  font-size: 18px;
  font-weight: 300;
  border: none;
  border-radius: 8px;
  padding: 10px 20px;
  background-color: #1fb19d;
  color: white;
  cursor: pointer;
  margin: 10px;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #9b0c4f;
}
.my-font-result {
  font-family: "Sunflower", sans-serif;
  font-size: 19px;
  font-weight: 400;
}
.my-margin {
  margin-bottom: 10px;
}
</style>
