const hangul = require("hangul-js");

let charToNum = {
  ㄱ: 2,
  ㄴ: 2,
  ㄷ: 3,
  ㄹ: 5,
  ㅁ: 4,
  ㅂ: 4,
  ㅅ: 2,
  ㅇ: 1,
  ㅈ: 3,
  ㅊ: 4,
  ㅋ: 3,
  ㅌ: 4,
  ㅍ: 4,
  ㅎ: 3,
  ㅏ: 2,
  ㅑ: 3,
  ㅓ: 2,
  ㅕ: 3,
  ㅗ: 2,
  ㅛ: 3,
  ㅜ: 2,
  ㅠ: 3,
  ㅡ: 1,
  ㅣ: 1,
  ㅐ: 3,
  ㅒ: 4,
  ㅔ: 3,
  ㅖ: 4,
  ㅘ: 4,
  ㅙ: 5,
  ㅚ: 3,
  ㅝ: 4,
  ㅞ: 5,
  ㅟ: 3,
  ㅢ: 2,
};

export function add(a, b) {
  return a + b;
}

export function makeHistoryArray(name1, name2) {
  console.log(name1, name2);
  let fixedScore = -1;

  if (name1 == "송용호" && name2 == "소다미") {
    fixedScore = 999;
  }

  let mergedNameArray = [];

  mergedNameArray = mergeNames(name1, name2);
  //console.log(mergedNameArray);

  return calcaulateScore(mergedNameArray, fixedScore);
}

function mergeNames(n1, n2) {
  let mergedArray = [];
  const maxLength = Math.max(3, Math.max(n1.length, n2.length));
  for (let i = 0; i < maxLength; i++) {
    if (n1[i] !== undefined) {
      mergedArray.push(n1[i]);
    } else {
      mergedArray.push("❤");
    }
    if (n2[i] !== undefined) {
      mergedArray.push(n2[i]);
    } else {
      mergedArray.push("❤");
    }
  }
  return mergedArray;
}

function calcaulateScore(mergedNameArray, fixedScore) {
  let mergedArray = [];
  for (let i = 0; i < mergedNameArray.length; i++) {
    let char = mergedNameArray[i];
    const disassembled = hangul.disassemble(char);
    const charSum = disassembled.reduce((sum, char) => sum + (charToNum[char] || 0), 0);
    mergedArray.push(charSum);
  }
  console.log(mergedArray);

  let outputHistory = [];

  outputHistory.push(mergedNameArray.slice());
  outputHistory.push(mergedArray.slice());
  while (mergedArray.length > 2) {
    mergedArray = mergedArray
      .map((num, idx, arr) => {
        return idx < arr.length - 1 ? (num + arr[idx + 1]) % 10 : null;
      })
      .filter((num) => num !== null);
    console.log(mergedArray);

    outputHistory.push(mergedArray.slice());
  }

  let resultScore = 0;
  if (mergedArray.length == 2) {
    resultScore = mergedArray[0] * 10 + mergedArray[1];
    if (resultScore == 0) {
      resultScore = 100;

      console.log(resultScore);
    }
  }
  if (fixedScore != -1) {
    resultScore = fixedScore;
  }
  outputHistory.push([resultScore]);
  return {
    outputHistory: outputHistory,
    resultScore: resultScore,
  };
  //this.manager.updateResultData(this.outputHistory);
}
