<template>
  <canvas ref="canvas" id="drawing"></canvas>
</template>

<script>
export default {
  data() {
    return {
      provider: {
        context: null,
      },
    };
  },

  provide() {
    return {
      provider: this.provider,
    };
  },
  mounted() {
    let canvas = this.$refs.canvas;
    this.provider.context = canvas.getContext("2d");
  },
  methods: {
    getMousePos(canvasDom, mouseEvent) {
      var rect = canvasDom.getBoundingClientRect();
      return {
        x: mouseEvent.clientX - rect.left,
        y: mouseEvent.clientY - rect.top,
      };
    },
    getContext() {
      return this.provider.context;
    },
    getCanvas() {
      return this.$refs["canvas"];
    },
  },
};
</script>
